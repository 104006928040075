// CardDesign.js
import React, { useState } from 'react';
import api from '../stackAPI';
import '../css/CardDesign.css';
import '../App.css';
import logo from '../assets/stack-card-logo-white.png';

const CardDesign = ({
  amount,
  setAmount,
  design,
  setDesign,
  message,
  setMessage,
  voucherValues,
  bitcoinValue,
  receiverName,
  receiverEmail,
  senderName,
  senderEmail,
  onNext,
  onBack,
}) => {
  const [voucherAvailable, setVoucherAvailable] = useState(true);

  const handleNext = async () => {
    if (amount && design) {
      try {
        const response = await api.get(`/api/vouchers/availability/${amount}`);
        if (response.data.available) {
          setVoucherAvailable(true);
          onNext(
            amount,
            receiverName,
            receiverEmail,
            senderName,
            senderEmail,
            message,
            design
          ); // Pass necessary parameters to onNext
        } else {
          setVoucherAvailable(false);
        }
      } catch (error) {
        console.error('Error checking voucher availability:', error);
        alert(
          'An error occurred while checking voucher availability. Please try again later.'
        );
      }
    } else {
      alert('Please select an amount and card design before proceeding.');
    }
  };
  console.log(design);
  return (
    <div className='card-container'>
      <div className='card-design-container'>
        <div className='column-layout'>
          <div className='left-column'>
            <div className='left-column-wrapper'>
              <h1>Next, design your BitGift.</h1>
              <p>
                Craft Your Masterpiece. Select the amount, choose a theme, and
                pen down a message that warms the heart.
              </p>
              <div className='amount-options'>
                {voucherValues
                  .filter((value) => value !== 1000)
                  .map((value) => (
                    <button
                      key={value}
                      className={`amount-option ${
                        amount === value ? 'selected' : ''
                      }`}
                      onClick={() => setAmount(value)}
                    >
                      ${value}
                    </button>
                  ))}
              </div>
            </div>
          </div>
          <div className='right-column'>
            <div className='card-preview'>
              <div className={`card-design ${design}`}>
                <img src={logo} alt='Logo' className='card-logo' />
                <div className='btc-value'>
                  {parseFloat(bitcoinValue).toFixed(6)}
                </div>
                <div className='btc-text'>BTC</div>
                <div className='personal-message'>
                  {message.trim()
                    ? message
                    : `${senderName} sent you this BitGift`}
                </div>
              </div>
            </div>
          </div>
          <div className='last-column'>
            <div className='right-column-wrapper'>
              <div className='design-thumbnails'>
                {[
                  'design1',
                  'design2',
                  'design3',
                  'design4',
                  'design5',
                  'design6',
                  'design7',
                  'design8',
                ].map((designOption) => (
                  <div
                    key={designOption}
                    className={`design-thumbnail ${
                      design === designOption ? 'selected' : ''
                    } ${designOption}`}
                    onClick={() => setDesign(designOption)}
                  ></div>
                ))}
              </div>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder='Add a personal note...'
              ></textarea>
              <div className='button-container'>
                {!voucherAvailable && (
                  <p className='error-message'>
                    The selected voucher amount is not available. Please choose
                    a different amount.
                  </p>
                )}
                <button className='back-button' onClick={onBack}>
                  Back
                </button>
                <button
                  className='checkout-button'
                  onClick={handleNext} // Use handleNext here
                  disabled={!amount || !voucherAvailable} // Disable button if no amount is selected or voucher is not available
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDesign;
