// RedeemSuccess.js
import React from 'react';
import redeemSuccessImg from '../assets/redeem-success.png';
import '../css/RedeemSuccess.css';
const RedeemSuccess = () => {
  return (
    <div className='redeem-successs-conatiner'>
      <div className='redeem-successs-wrapper'>
        <div className='redeem-success'>
          <div className='imgWrapper'>
            <img src={redeemSuccessImg} alt='Redeem Success Image' />
          </div>
          <h1>Voucher Redeemed Successfully</h1>
          <p>
            Your voucher has been successfully redeemed. The Bitcoin transfer
            will be initiated shortly.
          </p>
          <button>Home</button>
        </div>
      </div>
    </div>
  );
};

export default RedeemSuccess;
