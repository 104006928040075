// VoucherSelection.js
import React from 'react';
import '../css/VoucherSelection.css';

const VoucherSelection = ({
  receiverName,
  setReceiverName,
  receiverEmail,
  setReceiverEmail,
  senderName,
  setSenderName,
  senderEmail,
  setSenderEmail,
  onNext,
}) => {
  return (
    <div className='voucher-selection'>
      <div className='voucher-selection-wrapper'>
        <h1>First, personalize your BitGift.</h1>
        <p>
          Who's the Lucky Recipient? Start by telling us who this BitGift is for
          and who's sending it.
        </p>
        <div className='desktop'>
          <div className='receiver-bar'>
            <div className='label'>To</div>
            <div className='input-wrapper'>
              <input
                type='text'
                placeholder="Recipient's Name"
                value={receiverName}
                onChange={(e) => setReceiverName(e.target.value)}
                required
              />
              <input
                type='email'
                placeholder="Recipient's Email"
                value={receiverEmail}
                onChange={(e) => setReceiverEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className='sender-bar'>
            <div className='label'>From</div>
            <div className='input-wrapper'>
              <input
                type='text'
                placeholder="Sender's Name"
                value={senderName}
                onChange={(e) => setSenderName(e.target.value)}
                required
              />
              <input
                type='email'
                placeholder="Sender's Email"
                value={senderEmail}
                onChange={(e) => setSenderEmail(e.target.value)}
                required
              />
            </div>
          </div>
        </div>

        <div className='mobile'>
          <div className='receiver-bar-m'>
            <div className='label-m'>To</div>
            <div className='input-wrapper-m'>
              <input
                type='text'
                placeholder="Recipient's Name"
                value={receiverName}
                onChange={(e) => setReceiverName(e.target.value)}
                required
              />
              <input
                type='email'
                placeholder="Recipient's Email"
                value={receiverEmail}
                onChange={(e) => setReceiverEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className='sender-bar-m'>
            <div className='label-m'>From</div>
            <div className='input-wrapper-m'>
              <input
                type='text'
                placeholder="Sender's Name"
                value={senderName}
                onChange={(e) => setSenderName(e.target.value)}
                required
              />
              <input
                type='email'
                placeholder="Sender's Email"
                value={senderEmail}
                onChange={(e) => setSenderEmail(e.target.value)}
                required
              />
            </div>
          </div>
        </div>
      </div>
      <button className='design-button' onClick={onNext}>
        Design Your BitGift
      </button>
    </div>
  );
};

export default VoucherSelection;
