// /components/PurchaseError.js
import React from 'react';
import '../css/PurchaseError.css';

const PurchaseError = () => {
  return (
    <div className="purchase-error">
      <h2>Oops! Something went wrong.</h2>
      <p>We apologize for the inconvenience. It seems there was an error processing your purchase.</p>
      <p>Please try again later or contact our support team for assistance.</p>
      <p>
        <a href="/">Go back to the homepage</a>
      </p>
    </div>
  );
};

export default PurchaseError;
