import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/WalletSelection.css';

const WalletSelectionPage = () => {
  const [walletType, setWalletType] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [walletTypeError, setWalletTypeError] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const voucherCode = location.state?.voucherCode;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!walletType) {
      setWalletTypeError('Please select a wallet type.');
      return;
    }

    try {
      await axios
        .post(
          'https://api.giveabit.gift/api/vouchers/redeem',
          {
            code: voucherCode,
            walletType,
            walletAddress,
            email,
          },
          {
            validateStatus: function (status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        )
        .catch(function (error) {
          if (error.response) {
            console.log('Error response data:', error.response.data);
            console.log('Error response status:', error.response.status);
            console.log('Error response headers:', error.response.headers);
          } else if (error.request) {
            console.log('Error request:', error.request);
          } else {
            console.log('Error message:', error.message);
          }
          throw error;
        });

      navigate('/redeem-success');
    } catch (error) {
      console.error('Error redeeming voucher:', error);
      setMessage('Error redeeming voucher. Please try again.');
    }
  };

  return (
    <div className='wallet-selection-page'>
      <h1>Just One Step Away From Growth</h1>
      <p>
        You're moments from adding a promising asset to your portfolio. Fill in
        the details below to complete your BitGift Bitcoin transfer.
      </p>
      <form onSubmit={handleSubmit}>
        <div className='radios'>
          <label className='radio-1'>
            <input
              type='radio'
              value='lightning'
              checked={walletType === 'lightning'}
              onChange={(e) => {
                setWalletType(e.target.value);
                setWalletTypeError('');
              }}
            />
            <span>Lightning Wallet</span>
          </label>
          <label className='radio-2'>
            <input
              type='radio'
              value='onchain'
              checked={walletType === 'onchain'}
              onChange={(e) => {
                setWalletType(e.target.value);
                setWalletTypeError('');
              }}
            />
            <span>On-chain Wallet</span>
          </label>
        </div>
        {walletTypeError && <p className='error'>{walletTypeError}</p>}
        <div className='inputs-container'>
          <div className='email-input'>
            <label>Email</label>
            <input
              type='email'
              placeholder='Enter your email address'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              // className='redeem-input'
              required
            />
          </div>
          <div className='wallet-address-input'>
            <label>Wallet Address</label>
            <input
              type='text'
              placeholder='Enter your Bitcoin wallet address'
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
              // className='redeem-input'
              required
            />
          </div>
        </div>
        <div className='btn-wrraper'>
          <button type='submit'>Transfer</button>
        </div>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default WalletSelectionPage;
