// stackAPI.js

import axios from 'axios';

// Determine the base URL based on the environment
const baseURL = process.env.NODE_ENV === 'production'
  ? 'https://api.giveabit.gift'
  : 'http://localhost:3000';

const api = axios.create({
  baseURL,
});

export default api;