// VoucherPurchase.js
import React, { useState, useEffect } from 'react';
import api from '../stackAPI';
import VoucherSelection from './VoucherSelection';
import CardDesign from './CardDesign';
import Checkout from './Checkout';
import { loadStripe } from '@stripe/stripe-js';
import '../css/VoucherPurchase.css';
import { v4 as uuidv4 } from 'uuid';

const stripePromise = loadStripe('pk_test_51MmN07CLMRxmty22dwEv1m9D0QFLvjANZNRLTlGEwx7RBspclmepyXEGULmuKJUWF6bBf6VfmdP2brxAlFJpjwL700VDlEpeQC');

const VoucherPurchase = () => {
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState(0);
  const [design, setDesign] = useState('design1');
  const [message, setMessage] = useState('');
  const [voucherValues, setVoucherValues] = useState([]);
  const [bitcoinValue, setBitcoinValue] = useState(0);
  const [receiverName, setReceiverName] = useState('');
  const [receiverEmail, setReceiverEmail] = useState('');
  const [senderName, setSenderName] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); 

  useEffect(() => {
    const fetchVoucherValues = async () => {
      try {
        const response = await api.get('/api/vouchers/values');
        if (response.status === 200 && Array.isArray(response.data)) {
          setVoucherValues(response.data);
        } else {
          console.log('No voucher values available.');
        }
      } catch (error) {
        console.error('Error fetching voucher values:', error);
        // Display an error message to the user
        alert('An error occurred while fetching voucher values. Please try again later.');
      }
    };
    fetchVoucherValues();
  }, []);

  useEffect(() => {
    const fetchBitcoinValue = async () => {
      try {
        const response = await api.get(`/api/vouchers/bitcoin-value?amount=${amount}`);
        if (response.status === 200) {
          setBitcoinValue(response.data.bitcoinValue);
        } else {
          console.log('Error fetching Bitcoin value.');
        }
      } catch (error) {
        console.error('Error fetching Bitcoin value:', error);
        // Display an error message to the user
        alert('An error occurred while fetching Bitcoin value. Please try again later.');
      }
    };
    if (amount > 0) {
      fetchBitcoinValue();
    }
  }, [amount]);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (amount, receiverName, receiverEmail, senderName, senderEmail, message, design) => {
    if (isSubmitting) return;  // Prevents further submissions if one is already in progress
    setIsSubmitting(true);  // Indicate that submission is now in progress
  
    if (amount && design && receiverName && receiverEmail && senderName && senderEmail) {
      try {
        const purchaseId = uuidv4();
        console.log('Preparing to send to Stripe Checkout...');
        const stripe = await stripePromise;
        const response = await api.post('/api/vouchers/purchase', {
          value: amount,
          fromEmail: senderEmail,
          toEmail: receiverEmail,
          message,
          design,
          receiverName,
          senderName,
          purchaseId,
        });
  
        if (response.status === 200) {
          const { sessionId } = response.data;
          const { error } = await stripe.redirectToCheckout({ sessionId });
  
          console.log('Stripe Checkout returned, preparing to call success endpoint...');
          if (error) {
            console.error('Error redirecting to Stripe Checkout:', error);
            alert('An error occurred while redirecting to Stripe Checkout. Please try again later.');
          }
        } else {
          console.error('Error purchasing voucher:', response.data);
          alert('An error occurred while processing your purchase. Please try again later.');
        }
      } catch (error) {
        console.error('Error purchasing voucher:', error);
        alert('An error occurred while processing your purchase. Please try again later.');
      } finally {
        setIsSubmitting(false);  // Reset isSubmitting to false after the process is done or fails
      }
    } else {
      alert('Please fill in all the required fields before proceeding to checkout.');
      setIsSubmitting(false);  // Ensure to reset submission flag on validation fail
    }
  };
  

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <VoucherSelection
            receiverName={receiverName}
            setReceiverName={setReceiverName}
            receiverEmail={receiverEmail}
            setReceiverEmail={setReceiverEmail}
            senderName={senderName}
            setSenderName={setSenderName}
            senderEmail={senderEmail}
            setSenderEmail={setSenderEmail}
            onNext={handleNext}
          />
        );
      case 2:
        return (
          <CardDesign
            amount={amount}
            setAmount={setAmount}
            design={design}
            setDesign={setDesign}
            message={message}
            setMessage={setMessage}
            voucherValues={voucherValues}
            bitcoinValue={bitcoinValue}
            receiverName={receiverName}
            receiverEmail={receiverEmail}
            senderName={senderName}
            senderEmail={senderEmail}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 3:
        return (
          <Checkout
            amount={amount}
            bitcoinValue={bitcoinValue}
            receiverName={receiverName}
            receiverEmail={receiverEmail}
            senderName={senderName}
            senderEmail={senderEmail}
            message={message}
            design={design}
            onSubmit={() => handleSubmit(amount, receiverName, receiverEmail, senderName, senderEmail, message, design)}
            onBack={handleBack}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="voucher-purchase">
      <div className={`step step-${step}`}>
        {renderStep()}
      </div>
    </div>
  );
};

export default VoucherPurchase;