// coinrankingService.js

import axios from 'axios';

const COINRANKING_API_URL = 'https://api.coinranking.com/v2';
const API_KEY = 'coinrankinge00e0c861b14d5d3164f16b8cb72d6f3b0efb313267a9445';

export const getBitcoinData = async (timePeriod) => {
    try {
      const response = await axios.get(`${COINRANKING_API_URL}/coin/Qwsogvtv82FCd?timePeriod=${timePeriod}`, {
        headers: {
          'x-access-token': API_KEY
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Bitcoin data:', error);
      return null;
    }
  };

  