// components/Checkout.js
import React from 'react';
import '../css/Checkout.css';
import logo from '../assets/stack-card-logo-white.png';

const Checkout = ({
  amount,
  bitcoinValue,
  receiverName,
  receiverEmail,
  senderName,
  senderEmail,
  message,
  design,
  onSubmit,
  onBack,
}) => {
  return (
    <div className='checkout'>
      <div className='checkout-wrapper'>
        <div className=''>
          <h1>Almost there. Review and Confirm.</h1>
          <p>
            Take a moment to ensure everything looks perfect. You’re about to
            make someone’s day – and future.
          </p>
        </div>
        {/* <div className='checkout-card-design'> */}
        <div className='checkout-preview'>
          <div className={`checkout-design ${design}`}>
            <img src={logo} alt='Logo' className='checkout-logo' />
            <div className='btc-value'>
              {parseFloat(bitcoinValue).toFixed(6)}
            </div>
            <div className='btc-text'>BTC</div>
            <div className='personal-message'>
              {message.trim() ? message : `${senderName} sent you this BitGift`}
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className='checkout-info'>
          <p>
            <b>To:</b>
            {receiverName} ({receiverEmail})
          </p>
          <p>
            <b> From: </b>
            {senderName} ({senderEmail})
          </p>
          <p>Personal Note: {message}</p>
          <p>
            {' '}
            <b>Transaction Fee: No fees during beta!</b>
          </p>
          <p>
            {' '}
            <b> Satoshi Value: </b>
            {(bitcoinValue * 100000000).toFixed(0)} Satoshi
          </p>
          <p>
            <b>Amount:</b>${amount}
          </p>
          <button className='back-button' onClick={onBack}>
            Edit
          </button>
          <button
            onClick={() =>
              onSubmit(
                amount,
                receiverName,
                receiverEmail,
                senderName,
                senderEmail,
                message,
                design
              )
            }
            className='buynow-button'
          >
            Buy and send BitGift
          </button>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
