import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VoucherPurchase from './components/VoucherPurchase';
import PurchaseSuccess from './components/purchaseSuccess';
import PurchaseError from './components/PurchaseError';
import RedeemVoucher from './components/VoucherRedemption';
import RedeemSuccess from './components/RedeemSuccess';
import WalletSelectionPage from './components/WalletSelectionPage';
import './App.css';
import Footer from './components/Footer';

const App = () => {
  return (
    <Router>
      <div className='app'>
        {/* <img src={logo} alt='Logo' className='logo' /> */}
        <div className='logo'>BitGifts</div>
        <Routes>
          <Route path='/' element={<VoucherPurchase />} />
          <Route path='/purchase-success' element={<PurchaseSuccess />} />
          <Route path='/purchase-error' element={<PurchaseError />} />
          <Route path='/redeem' element={<RedeemVoucher />} />
          <Route path='/redeem-success' element={<RedeemSuccess />} />
          <Route path='/wallet-selection' element={<WalletSelectionPage />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
